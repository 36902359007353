import './NoticiasBody.css';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../hooks';
import { useEffect, useState } from 'react';
import { IListNoticia, sessionNoticiasService } from '../../services/api/noticias/NoticiasService';
import { MainBody } from './MainBody';
import { Col, Container, Row } from 'react-bootstrap';

import { ListNoticia } from '../generic';
import { NoticiasBodyContent } from './NoticiasBodyContent';
import { NoticiasBodyDetail } from './NoticiasBodyDetail';

export interface INoticiasProps {
  fromHomePage: boolean;
  noticia?: IListNoticia;
  showDetail?: boolean;
}

export const NoticiasBody: React.FC<INoticiasProps> = ({ fromHomePage, showDetail, noticia }) => {
  const { i18n } = useTranslation('noticias');
  const { debounce } = useDebounce();
  const { getAll } = sessionNoticiasService();

  const [isLoading, setIsLoading] = useState(true);
  const [noticias, setNoticias] = useState<IListNoticia[]>([]);
  const [detalheNoticia, setDetalheNoticia] = useState<IListNoticia>();
  const [destaques, setDestaques] = useState<IListNoticia[]>([]);

  const handleNoticiaSelecionada = (noticia: IListNoticia) => {
    setDetalheNoticia(noticia);
  };

  useEffect(() => {
    if (!showDetail) {
      setIsLoading(true);
      debounce(() => {
        let language = i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase();
        getAll(language).then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            return;
          } else {
            setNoticias(result);

            setDestaques(
              result
                .filter((noticia) => noticia.destaque === 'S')
                .sort((a, b) => (a.dtPublicacao > b.dtPublicacao ? -1 : 1))
            );
          }
        });
      });
    } else {

      setDetalheNoticia(noticia);
    }
  }, [debounce, detalheNoticia, getAll, i18n.language, noticia, showDetail]);

  return (
    <MainBody pageClassName='NoticiasClass'>
      <Container>
        <Row>
          <Col className='ContornoLinha coluna-esquerda' xxl='8' xl='8' lg='8'>
            {detalheNoticia ? (
              <NoticiasBodyDetail noticia={detalheNoticia!} />
            ) : (
              <NoticiasBodyContent
                noticias={
                  fromHomePage
                    ? destaques
                    : noticias.slice(0, 2).sort((a, b) => (a.dtPublicacao > b.dtPublicacao ? -1 : 1))
                }
              />
            )}
          </Col>

          <Col className='ContornoLinha coluna-direita'>
            <ListNoticia noticias={noticias.slice(2)} noticiaSelecionada={handleNoticiaSelecionada} />
          </Col>
        </Row>
      </Container>
    </MainBody>
  );
};
